<template>
  <div id="transfer" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <form @submit.prevent="passes(initializePromote)">
          <div class="columns is-multiline">
            <div class="column is-6">
              <SdInput
                type="text"
                rules="required"
                label="Current School Class"
                v-model="currentSchoolClass.name"
                placeholder="Name"
                readonly
              />
            </div>
            <div class="column is-6">
              <SdSelect
                rules="required"
                label="New School Class"
                v-model="newSchoolClass"
              >
                <option
                  v-for="schoolClass in schoolClasses"
                  :key="schoolClass.id"
                >
                  {{ schoolClass.name }}
                </option>
              </SdSelect>
            </div>
            <div class="column is-12">
              <b-table
                :data="studentsInClass"
                :checked-rows.sync="checkedStudents"
                checkable
                hoverable
              >
                <b-table-column
                  field="#"
                  label="#"
                  width="40"
                  numeric
                  v-slot="props"
                  >{{ studentsInClass.indexOf(props.row) + 1 }}</b-table-column
                >

                <b-table-column label="RegId" v-slot="props">{{
                  props.row.regId.toUpperCase()
                }}</b-table-column>

                <b-table-column label="Name" v-slot="props">{{
                  props.row.name
                }}</b-table-column>

                <template slot="bottom-left">
                  <SdInput
                    type="text"
                    rules="required"
                    label="Total checked"
                    v-model="checkedStudents.length"
                    placeholder="Total checked"
                    readonly
                  />
                </template>
              </b-table>
            </div>
            <div class="column is-12">
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Promote
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <!-- Delete -->
    <app-modal
      id="promote-students"
      context="update"
      @update="submit"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>
          {{ promotionMessage }}
        </p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

export default {
  name: 'student',
  data() {
    return {
      schoolId: null,
      newSchoolClassId: null,
      newSchoolClass: null,
      termId: null,
      pageTitle: 'Promote',
      currentSchoolClass: {},
      schoolClassId: null,
      schoolClasses: [],
      studentsInClass: [],
      checkedStudents: [],
      promotionMessage: '',
    }
  },
  apollo: {
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: parseInt(this.$route.params.school_id) }
      },
    },
  },
  watch: {
    schoolClasses(data) {
      this.currentSchoolClass = data.filter(
        (school_class) => school_class.id === this.schoolClassId
      )[0]
    },
    newSchoolClass(n) {
      this.newSchoolClassId = parseInt(
        this.schoolClasses.filter((school_class) => school_class.name === n)[0]
          .id
      )
    },
  },
  methods: {
    initializePromote() {
      this.promotionMessage = `You want to promote these students from ${this.currentSchoolClass.name} to ${this.newSchoolClass}?`
      this.openModal()
    },
    submit() {
      this.promotionMessage = 'Please wait...Promotion in progress.'
      const oldSchoolClassId = parseInt(this.schoolClassId)
      const studentIds = this.checkedStudents.map((student) => student.id)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation promoteStudents(
              $oldSchoolClassId: Int!
              $newSchoolClassId: Int!
              $studentIds: [String!]!
            ) {
              promoteStudents(
                input: {
                  oldSchoolClassId: $oldSchoolClassId
                  newSchoolClassId: $newSchoolClassId
                  studentIds: $studentIds
                }
              ) {
                errors
              }
            }
          `,
          variables: {
            oldSchoolClassId: oldSchoolClassId,
            newSchoolClassId: this.newSchoolClassId,
            studentIds: studentIds,
          },
        })
        .then((response) => {
          if (response.data.promoteStudents.errors.length === 0) {
            this.closeModal()

            this.$buefy.notification.open({
              duration: 5000,
              message: 'Successfully promoted',
              position: 'is-top',
              type: 'is-info',
              hasIcon: true,
            })
            this.$router.push(
              `/school/${this.schoolId}/students/${this.newSchoolClassId}`
            )
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    fetchTerm().then((term) => {
      this.termId = term.id
    })
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = this.$route.params.id
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$apollo.addSmartQuery('studentsInClass', {
      query: gql`
        query studentsInClass($schoolClassId: Int!) {
          studentsInClass(schoolClassId: $schoolClassId) {
            id
            regId
            name
          }
        }
      `,
      variables: {
        schoolClassId: parseInt(this.schoolClassId),
      },
    })
    this.$store.commit('setSubMenus', [
      {
        name: 'School Class Info',
        route: `/school/${this.schoolId}/school_class_info/${this.$route.params.id}`,
      },
    ])
  },
}
</script>
